module.exports = {
  "strings": {
    "title": "PROVED! NewProfilePic is 100% SAFE",

    "need_help__title": "Дорогие друзья, нам нужна ваша помощь!",
    "need_help__text_1": "С тех пор, как около недели назад приложение NewProfilePic побило все рекорды по росту популярности, в британских таблоидах и социальных сетях стали распространяться нелепые домыслы и сообщения - начиная с того, что это российское вредоносное приложение, заканчивая тем, что оно ворует деньги пользователей.",
    "need_help__text_2": "<span>Все</span> эти предположения, конечно же, не выдерживают никакой критики, и они были полностью опровергнуты платформами по проверке фактов, такими как <a href=\"https://www.snopes.com/news/2022/05/11/new-profile-pic-app/\" target=\"_blank\" rel=\"noopener noreferrer\">Snopes</a> и <a href=\"https://www.politifact.com/factchecks/2022/may/16/facebook-posts/newprofilepic-app-not-russian-spy-malware-heres-ho/\" target=\"_blank\" rel=\"noopener noreferrer\">PolitiFact</a>, а также организациями по защите интересов потребителей, такими как <a href=\"https://www.kfvs12.com/2022/05/13/bbb-says-new-profile-pic-app-isnt-leaking-your-personal-information-russia/?fr=operanews\" target=\"_blank\" rel=\"noopener noreferrer\">Better Business Bureau</a>.",
    "need_help__text_3": "Тем не менее, все происходящее не может не расстраивать нас, поскольку зачастую люди <a href=\"https://www.instagram.com/p/CdtMShvonh2/\" target=\"_blank\" rel=\"noopener noreferrer\">склонны больше доверять сенсационным, вводящим в заблуждение сообщениям</a>, нежели проверенным фактам, ведь «нет дыма без огня».",
    "need_help__text_4": "Это воспринимается особенно болезненно потому, что мы — интернациональная команда разработчиков фото-приложений, отмеченных многочисленными наградами. Команда людей, которые горят своей работой и гордятся своей компанией.",
    "need_help__text_5": "Это также не может не расстраивать тех пользователей, которые сначала нашли классное приложение для создания аватарок, а потом, после прочтения этих тревожных историй, были вынуждены удалить его.",
    "need_help__text_6": "Пора положить этому конец - и вы можете помочь нам дать отпор домыслам и ложным обвинениям! Пожалуйста, поделитесь этой страницей в социальных сетях, чтобы рассказать правду о NewProfilePic. А также перешлите ее всем друзьям, которые, возможно, отговаривали вас от использования нашего приложения.",

    "about_us__title": "О нас",
    "about_us__text_1": "Нам всегда казалось, что важнее рассказывать о наших приложениях, а не о компании как таковой, но, учитывая все обвинительные сообщения, думаем, стоит рассказать о себе немного больше.",
    "about_us__text_2": "Прежде всего, мы существуем уже довольно давно, и наши приложения всегда получали отличные отзывы в Google Play и Apple Store!",

    "about_company__title": "Дополнительные факты о нашей компании",
    "about_company__2008_text_1": "Официальное название нашей компании, Linerock Investments LTD., было зарегистрировано более 20 лет назад. Прорыв произошел в 2008 году, когда мы запустили сайт <a href=\"https://pho.to\" target=\"_blank\" rel=\"noopener noreferrer\">https://pho.to</a>.",
    "about_company__2008_text_2": "Возможно, вы не слышали о нас раньше, но наша деятельность никогда не оставалась без внимания… мягко говоря :)",
    "about_company__2017_text_1": "Сам Железный Человек воспользовался нашими приложениями <a href=\"https://www.facebook.com/Pho.to.Editor/posts/robert-downey-jr-chooses-photolab-to-have-the-most-impressive-photos-and-share-t/1725738937467225/\" target=\"_blank\" rel=\"noopener noreferrer\">в 2017 году</a>, и в том же году наше приложение стало <a href=\"https://blog.pho.to/photo-labs-in-app-stores-trends-2017/\" target=\"_blank\" rel=\"noopener noreferrer\">“Трендом года” в App Store</a>.",
    "about_company__2018_text_1": "Возможно, вы слышали историю о том, как в 2018 году обычная фанатка предложила своему кумиру <a href=\"https://blog.pho.to/shawn-mendes-album-photolab-art/\" target=\"_blank\" rel=\"noopener noreferrer\">идею дизайна обложки для его музыкального альбома</a>… конечно же, это было сделано с помощью нашего приложения.",
    "about_company__2020_text_1": "В 2020 году приложение <span className=\"bold\">Photo Lab</span> <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">побило все рекорды популярности</a>, достигнув верхней позиции в нескольких крупных странах, включая Индию. Именно тогда появились первые слухи из серии «Этот быстрый рост вызывает подозрения», и нас начали <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">связывать с ЦРУ</a>.",
    "about_company__2021_text_1": "В прошлом году наше (на тот момент новое) приложение <span className=\"bold\">ToonMe</span> вырвалось на первое место в топе всех приложений в десятках стран, <a href=\"https://toonme.com/woohoo\" target=\"_blank\" rel=\"noopener noreferrer\">включая Китай, Японию и Италию</a>, а приложение <span className=\"bold\">Photo Lab</span> стало самым популярным <a href=\"https://blog.pho.to/how-2021-has-been-for-photo-lab/\" target=\"_blank\" rel=\"noopener noreferrer\">в большинстве стран Африки</a>.",

    "conclusion__title": "Поэтому нет, мы не мошенники и не компания-однодневка!",
    "conclusion__text_1": "Мы зрелая, сформировавшаяся компания, которая трудится много лет, разрабатывая инновационные технологии для создания красивых визуальных эффектов.",
    "conclusion__text_2": "Мы никогда не занимались какой-либо сомнительной деятельностью и не были связаны с КАКИМ-ЛИБО правительством или правительственными организациями.",

    "any_questions": "Если у вас остались вопросы, <a href=\"mailto:newprofilepic@pho.to\">напишите нам</a>!",

    "share_text_1": "Пожалуйста, поделитесь этой страницей, чтобы помочь нам.",
    "share_text_2": "И пожалуйста, поделитесь этой страницей в соцсетях, чтобы помочь нам.",
    "share_in_instagram": "Или опубликуйте короткую ссылку <a href=\"https://facts.pho.to\" rel=\"noopener noreferrer\">facts.pho.to</a> в Instagram",

    "btn_back_npp": "На главную страницу",
  },
};