module.exports = {
  "strings": {
    "title": "PROVED! NewProfilePic is 100% SAFE",

    "need_help__title": "Cari amici, abbiamo bisogno del vostro aiuto!",
    "need_help__text_1": "Nel momento in cui, solo una settimana fa, NewProfilePic ha cominciato ad avere un successo sfrenato sono circolate strane informazioni sui tabloid britannici e sui social media che vanno dal fatto che NewProfilePic è un malware russo al fatto che la nostra app rubi denaro ai nostri utenti.",
    "need_help__text_2": "<span>Tutto</span> questo è, naturalmente, un'assurdità, ed è stato ampiamente smentito dalle autorità di verifica dei fatti come <a href=\"https://www.snopes.com/news/2022/05/11/new-profile-pic-app/\" target=\"_blank\" rel=\"noopener noreferrer\">Snopes</a> e <a href=\"https://www.politifact.com/factchecks/2022/may/16/facebook-posts/newprofilepic-app-not-russian-spy-malware-heres-ho/\" target=\"_blank\" rel=\"noopener noreferrer\">PolitiFact</a>, o da organizzazioni di consumatori come <a href=\"https://www.kfvs12.com/2022/05/13/bbb-says-new-profile-pic-app-isnt-leaking-your-personal-information-russia/?fr=operanews\" target=\"_blank\" rel=\"noopener noreferrer\">Better Business Bureau</a>.",
    "need_help__text_3": "Tuttavia, questo ci danneggia, in quanto notizie sensazionali e fuorvianti <a href=\"https://www.instagram.com/p/CdtMShvonh2/\" target=\"_blank\" rel=\"noopener noreferrer\">tendono a indugiare più a lungo dei fatti</a>, sulla falsariga di “dove c'è fumo, c'è fuoco“.",
    "need_help__text_4": "Ci danneggia, in particolare, perché siamo un gruppo internazionale di entusiasti sviluppatori di app fotografiche pluripremiate, orgogliosi del nostro lavoro e della nostra azienda.",
    "need_help__text_5": "Danneggia anche quegli utenti che hanno, prima, trovato un modo creativo per ravvivare le immagini del loro profilo, ma poi, dopo aver letto queste storie allarmanti, hanno sentito il bisogno di eliminare l'applicazione.",
    "need_help__text_6": "È ora di smetterla e voi potete aiutarci a reagire! Condividete questa pagina sui vostri social media per diffondere la verità su NewProfilePic. E inoltratela anche a tutti gli amici che potrebbero avervi avvisato dei rischi che state correndo in quanto utenti della nostra app!",

    "about_us__title": "Chi siamo",
    "about_us__text_1": "Ci siamo sempre concentrati sulla diffusione delle nostre app piuttosto che sulla nostra azienda, ma dati tutti questi report accusatori, vorremmo raccontare qualcosa di più sull'azienda.",
    "about_us__text_2": "Prima di tutto, siamo in giro da un bel po' di tempo e le nostre app hanno sempre avuto recensioni stellari su Google Play e Apple Store!",

    "about_company__title": "Alcune informazioni aggiuntive sulla nostra azienda",
    "about_company__2008_text_1": "Linerock Investments LTD., il nome ufficiale della nostra azienda, è stato registrato oltre 20 anni fa. La nostra azienda, in realtà, è partita con il nostro sito web <a href=\"https://pho.to\" target=\"_blank\" rel=\"noopener noreferrer\">https://pho.to</a> nel 2008.",
    "about_company__2008_text_2": "Potreste non aver mai sentito parlare di noi, ma siamo stati completamente sotto i riflettori... per non dire altro :)",
    "about_company__2017_text_1": "Iron Man stesso ha utilizzato le nostre app <a href=\"https://www.facebook.com/Pho.to.Editor/posts/robert-downey-jr-chooses-photolab-to-have-the-most-impressive-photos-and-share-t/1725738937467225/\" target=\"_blank\" rel=\"noopener noreferrer\">nel 2017</a>, lo stesso anno in cui Apple ci ha scelto come <a href=\"https://blog.pho.to/photo-labs-in-app-stores-trends-2017/\" target=\"_blank\" rel=\"noopener noreferrer\">Tendenza dell'anno</a>.",
    "about_company__2018_text_1": "Probabilmente nel 2018 avrete sentito la storia della <a href=\"https://blog.pho.to/shawn-mendes-album-photolab-art/\" target=\"_blank\" rel=\"noopener noreferrer\">copertina dei grandi album musicali</a> suggerita da un semplice fan… e, a quanto pare, è stata realizzata con l'aiuto della nostra app.",
    "about_company__2020_text_1": "Poi nel 2020 <span className=\"bold\">Photo Lab</span> ha iniziato ad avere un <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">grande successo</a>, raggiungendo il primo posto in moltissimi paesi, tra cui l'India. È stato allora che la nostra tendenza iniziale “Questa rapida crescita è sospetta“ ha iniziato a <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">collegarci alla CIA</a>.",
    "about_company__2021_text_1": "E l'anno scorso la nostra nuova app <span className=\"bold\">ToonMe</span> ha ottenuto la prima posizione assoluta in decine di paesi, <a href=\"https://toonme.com/woohoo\" target=\"_blank\" rel=\"noopener noreferrer\">tra cui Cina, Giappone e Italia</a>, mentre <span className=\"bold\">Photo Lab</span> è diventata l'app più popolare <a href=\"https://blog.pho.to/how-2021-has-been-for-photo-lab/\" target=\"_blank\" rel=\"noopener noreferrer\">in gran parte dell'Africa</a>.",

    "conclusion__title": "Quindi, no, non siamo una compagnia di truffe fly-by-night!",
    "conclusion__text_1": "Siamo un'azienda matura che ha lavorato duramente per molti anni sviluppando tecnologie innovative per creare bellissime immagini.",
    "conclusion__text_2": "Non siamo mai stati impegnati in attività discutibili o affiliati a NESSUN governo od organizzazione governativa.",

    "any_questions": "Per qualsiasi domanda, vi preghiamo di <a href=\"mailto:newprofilepic@pho.to\">inviarci un messaggio</a>!",

    "share_text_1": "CONDIVIDETE QUESTA PAGINA E AIUTATECI!",
    "share_text_2": "E, PER FAVORE, CONDIVIDETE QUESTA PAGINA E AIUTATECI A COMBATTERE!",
    "share_in_instagram": "Oppure menzionate il nostro breve <a href=\"https://facts.pho.to\" rel=\"noopener noreferrer\">facts.pho.to</a> url su Instagram",

    "btn_back_npp": "VAI ALLA PAGINA PRINCIPALE",
  },
};