module.exports = {
  "strings": {
    "title": "PROVED! NewProfilePic is 100% SAFE",

    "need_help__title": "Chers amis, nous avons besoin de votre aide&nbsp;!",
    "need_help__text_1": "À partir du moment où NewProfilePic a connu un succès fulgurant il y a tout juste une semaine, des rapports bizarres ont circulé dans les tabloïds britanniques et sur les médias sociaux, indiquant que NewProfilePic était un logiciel malveillant russe et que notre application volait l’argent de nos utilisateurs.",
    "need_help__text_2": "<span>Tout</span> cela est, bien sûr, absurde et  a été largement démenti par des autorités de vérification des faits comme <a href=\"https://www.snopes.com/news/2022/05/11/new-profile-pic-app/\" target=\"_blank\" rel=\"noopener noreferrer\">Snopes</a> et <a href=\"https://www.politifact.com/factchecks/2022/may/16/facebook-posts/newprofilepic-app-not-russian-spy-malware-heres-ho/\" target=\"_blank\" rel=\"noopener noreferrer\">PolitiFact</a>, ou des organisations de consommateurs comme <a href=\"https://www.kfvs12.com/2022/05/13/bbb-says-new-profile-pic-app-isnt-leaking-your-personal-information-russia/?fr=operanews\" target=\"_blank\" rel=\"noopener noreferrer\">Better Business Bureau</a>.",
    "need_help__text_3": "Pourtant, cela nous affecte, car les nouvelles sensationnelles et trompeuses ont <a href=\"https://www.instagram.com/p/CdtMShvonh2/\" target=\"_blank\" rel=\"noopener noreferrer\">tendance à s’attarder plus longtemps que les faits</a>, selon le principe «&nbsp;pas de fumée sans feu&nbsp;».",
    "need_help__text_4": "C’est particulièrement douloureux parce que nous sommes un groupe international de développeurs inspirés d’applications photo primées, qui sont fiers de leur travail et de leur entreprise.",
    "need_help__text_5": "Cela nuit également aux utilisateurs qui ont d’abord trouvé un moyen créatif de pimenter leur photo de profil, mais qui ont ensuite ressenti le besoin de supprimer l’application après avoir lu ces histoires alarmantes.",
    "need_help__text_6": "Il est temps de mettre un terme à cette situation – et vous pouvez nous aider à lutter contre ce phénomène&nbsp;! Veuillez partager cette page sur vos réseaux sociaux pour diffuser la vérité sur NewProfilePic. Et faites-la également parvenir à tous vos amis qui auraient pu vous avertir des risques encourus en utilisant notre application&nbsp;!",

    "about_us__title": "A propos de nous",
    "about_us__text_1": "Nous nous sommes toujours attachés à faire connaître nos applications plutôt que notre entreprise, mais compte tenu de tous ces rapports accusateurs, nous aimerions en dire un peu plus sur l’entreprise.",
    "about_us__text_2": "Tout d’abord, nous existons depuis un certain temps et nos applications ont toujours reçu d’excellentes critiques sur Google Play et Apple Store&nbsp;!",

    "about_company__title": "Quelques détails supplémentaires sur notre société",
    "about_company__2008_text_1": "Linerock Investments LTD, le nom officiel de notre société, a été enregistré il y a plus de 20 ans. Notre entreprise a réellement démarré avec notre site Web <a href=\"https://pho.to\" target=\"_blank\" rel=\"noopener noreferrer\">https://pho.to</a> en 2008.",
    "about_company__2008_text_2": "Il se peut que vous n’ayez pas entendu parler de nous auparavant, mais nous ne sommes pas complètement passés sous le radar... c’est le moins que l’on puisse dire :)",
    "about_company__2017_text_1": "Iron Man lui-même a utilisé nos applications <a href=\"https://www.facebook.com/Pho.to.Editor/posts/robert-downey-jr-chooses-photolab-to-have-the-most-impressive-photos-and-share-t/1725738937467225/\" target=\"_blank\" rel=\"noopener noreferrer\">en 2017</a>, l’année où Apple nous a choisis parmi <a href=\"https://blog.pho.to/photo-labs-in-app-stores-trends-2017/\" target=\"_blank\" rel=\"noopener noreferrer\">les tendances de l’année</a>.",
    "about_company__2018_text_1": "En 2018, vous avez probablement entendu l’histoire de la conception de <a href=\"https://blog.pho.to/shawn-mendes-album-photolab-art/\" target=\"_blank\" rel=\"noopener noreferrer\">la couverture d’un grand album de musique</a> suggérée par un simple fan... et apparemment, elle a été réalisée avec l’aide de notre application.",
    "about_company__2020_text_1": "Puis, en 2020, <span className=\"bold\">Photo Lab</span> est devenu <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">un grand succès</a>, atteignant la première place dans plusieurs grands pays, dont l’Inde. C’est alors que notre première tendance «&nbsp;Cette croissance rapide est suspecte&nbsp;» a commencé <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">à nous relier à la CIA</a>.",
    "about_company__2021_text_1": "Et l’année dernière, notre application <span className=\"bold\">ToonMe</span>, alors toute nouvelle, a obtenu la première place dans des dizaines de pays, <a href=\"https://toonme.com/woohoo\" target=\"_blank\" rel=\"noopener noreferrer\">dont la Chine, le Japon et l’Italie</a>, tandis que <span className=\"bold\">Photo Lab</span> est devenue l’application la plus populaire dans <a href=\"https://blog.pho.to/how-2021-has-been-for-photo-lab/\" target=\"_blank\" rel=\"noopener noreferrer\">la plupart des pays d’Afrique</a>.",

    "conclusion__title": "Donc non, nous ne sommes pas une société d’escroquerie au rabais&nbsp;!",
    "conclusion__text_1": "Nous sommes une entreprise mature qui travaille dur depuis de nombreuses années en développant des technologies innovantes pour créer de beaux visuels.",
    "conclusion__text_2": "Nous n’avons jamais été engagés dans des activités douteuses ou affiliés à un quelconque gouvernement ou organisation gouvernementale.",

    "any_questions": "Si vous avez des questions, <a href=\"mailto:newprofilepic@pho.to\">écrivez-nous</a>&nbsp;!",

    "share_text_1": "PARTAGEZ CETTE PAGE ET AIDEZ-NOUS&nbsp;!",
    "share_text_2": "ET S’IL VOUS PLAÎT, PARTAGEZ CETTE PAGE ET AIDEZ-NOUS À NOUS DÉFENDRE&nbsp;!",
    "share_in_instagram": "Ou mentionnez notre courte url <a href=\"https://facts.pho.to\" rel=\"noopener noreferrer\">facts.pho.to</a> sur Instagram.",

    "btn_back_npp": "ALLER À LA PAGE PRINCIPALE",
  },
};